import { ref } from "vue";
import { defineStore } from "pinia";
import {
  getUserMessage,
  getChangePassword,
  getChangeUserMessage,
  getPhoneCode,
  getServerList,
  getSocketsAndToken,
  getFileList,
  getFileContent,
  getDownloadFile,
  getRenameFile,
  getCopyFile,
  getWriteFile,
  getCompressFile,
  getDecompressFile,
  getDeleteFile,
  getCreateFolder,
  getChangePermission,
  getUploadLink,
  getSFTP,
  getServerStatus,
  getBackupList,
  getLockBackup,
  getDeleteBackup,
  getDownloadBackup,
  getStartBackup,
  getRecoverBackup,
  getBuildConfig,
  getChangeConfigById,
  getChangeConfigByEnv,
  getMirrorList,
  getChangeMirror,
  getReinstall,
  getCouponList,
  getReceiveCoupon,
  getUserCoupon,
  getOrderList,
  getRecharge,
  getRenew,
  getUpgrade,
  getPriceCalc,
  getSubmitUpgrade,
  getRefund,
  getDatabaseStatus,
  getNetwork,
  getCDKCharge,
} from "@/server/userCenter/userCenter";

const useUserCenterStore = defineStore(
  "userCenter",
  () => {
    // 用户信息
    const userInfo: any = ref({});
    // 服务器列表
    const serverList: any = ref([]);
    // sockets信息
    const socketsInfo: any = ref({});
    // 文件列表
    const fileList: any = ref([]);
    // 备份列表
    const backupList: any = ref([]);
    // 游戏配置
    const gameConfig: any = ref({});
    // 可用镜像
    const mirrorList: any = ref([]);
    // 服务器状态
    const serverStatus: any = ref({});
    // 优惠券列表
    const couponList: any = ref([]);
    // 用户优惠券
    const userCouponList: any = ref([]);
    // 用户订单
    const orderList: any = ref({});
    // 升降配信息
    const upgradeInfo: any = ref({});
    // 服务器在数据库中的状态
    const databaseStatus: any = ref("");
    // 网络信息
    const networkInfo: any = ref({});

    /**
     * @description 个人设置
     */
    // 获取用户信息
    const getUserMessageInfo = async () => {
      const res: any = await getUserMessage();
      userInfo.value = res.data;
      // console.log(res);
      return res;
    };

    // 修改用户信息
    const getChangeUserMessageInfo = async (data: any) => {
      const res = await getChangeUserMessage(data);
      // console.log(res);
      return res;
    };

    // 修改密码
    const getChangePasswordInfo = async (config: {}) => {
      const res = await getChangePassword(config);
      // console.log(res);
      return res;
    };

    // 获取验证码
    const getPhoneCodeInfo = async () => {
      const res = await getPhoneCode();
      // console.log(res);
      return res;
    };

    /**
     * @description 我的游戏服
     */
    // 获取服务器列表
    const getServerListInfo = async (limit: number, currentPage: number) => {
      const res: any = await getServerList(limit, currentPage);
      if (res.data.data) {
        serverList.value = res.data.data;
      }
      // console.log(res);
      return res;
    };

    // 获取服务器状态-守护进程
    const getServerStatusInfo = async (id: number) => {
      const res: any = await getServerStatus(id);
      serverStatus.value = res.data;
      // console.log(res);
      return res;
    };

    // 获取服务器状态-数据库
    const getDatabaseStatusInfo = async (id: number) => {
      const res: any = await getDatabaseStatus(id);
      databaseStatus.value = res.data.status;
      // console.log(res);
      return res;
    };

    /**
     * @description 我的游戏服-控制台
     */
    // 获取sockets地址与token
    const getSocketsAndTokenInfo = async (id) => {
      const res: any = await getSocketsAndToken(id);
      socketsInfo.value = res.data;
      // console.log(res);
      return res;
    };

    // 重装
    const getReinstallInfo = async (id: number) => {
      const res = await getReinstall(id);
      // console.log(res);
      return res;
    };

    /**
     * @description 我的游戏服-文件
     */
    // 文件列表
    const getFileListInfo = async (id: number, path: string) => {
      const res: any = await getFileList(id, path);
      fileList.value = res.data;
      // console.log(res);
      return res;
    };

    // 获取文件内容
    const getFileContentInfo = async (data: {}) => {
      const res = await getFileContent(data);
      // console.log(res);
      return res;
    };

    // 下载文件
    const getDownloadFileInfo = async (data: {}) => {
      const res = await getDownloadFile(data);
      // console.log(res);
      return res;
    };

    // 重命名文件
    const getRenameFileInfo = async (data: {}) => {
      const res = await getRenameFile(data);
      // console.log(res);
      return res;
    };

    // 复制文件 复制文件到当前路径下
    const getCopyFileInfo = async (data: {}) => {
      const res = await getCopyFile(data);
      // console.log(res);
      return res;
    };

    // 写入文件内容，创建并写入内容
    const getWriteFileInfo = async (data: {}) => {
      const res = await getWriteFile(data);
      // console.log(res);
      return res;
    };

    // 压缩文件
    const getCompressFileInfo = async (data: {}) => {
      const res = await getCompressFile(data);
      // console.log(res);
      return res;
    };

    // 解压文件
    const getUnCompressFileInfo = async (data: {}) => {
      const res = await getDecompressFile(data);
      // console.log(res);
      return res;
    };

    // 删除文件
    const getDeleteFileInfo = async (data: {}) => {
      const res = await getDeleteFile(data);
      return res;
    };

    // 新建文件夹
    const getCreateFolderInfo = async (data: {}) => {
      const res = await getCreateFolder(data);
      // console.log(res);
      return res;
    };

    // 修改权限
    const getChangePermissionInfo = async (data: {}) => {
      const res = await getChangePermission(data);
      // console.log(res);
      return res;
    };

    // 获取上传链接
    const getUploadLinkInfo = async (id: number) => {
      const res = await getUploadLink(id);
      // console.log(res);
      return res;
    };

    // 获取SFTP链接信息
    const getSFTPInfo = async (id: number) => {
      const res = await getSFTP(id);
      // console.log(res);
      return res;
    };

    /**
     * @description 我的游戏服-备份
     */

    // 服务器备份列表
    const getBackupListInfo = async (id) => {
      const res: any = await getBackupList(id);
      backupList.value = res.data;
      // console.log(res);
      return res;
    };

    // 切换锁定状态
    const getLockBackupInfo = async (id) => {
      const res = await getLockBackup(id);
      // console.log(res);
      return res;
    };

    // 删除备份
    const getDeleteBackupInfo = async (id) => {
      const res = await getDeleteBackup(id);
      // console.log(res);
      return res;
    };

    // 获取下载链接
    const getDownloadBackupInfo = async (id) => {
      const res = await getDownloadBackup(id);
      // console.log(res);
      return res;
    };

    // 开始备份
    const getStartBackupInfo = async (data) => {
      const res = await getStartBackup(data);
      // console.log(res);
      return res;
    };

    // 备份恢复服务器
    const getRecoverBackupInfo = async (data) => {
      const res = await getRecoverBackup(data);
      // console.log(res);
      return res;
    };

    /**
     * @description 我的游戏服-开服配置
     */

    // 获取构建配置
    const getBuildConfigInfo = async (id) => {
      const res: any = await getBuildConfig(id);
      gameConfig.value = res.data;
      // console.log(res);
      return res;
    };

    // 修改变量 - id
    const getChangeConfigByIdInfo = async (data) => {
      const res = await getChangeConfigById(data);
      // console.log(res);
      return res;
    };

    // 修改变量 - env
    const getChangeConfigByEnvInfo = async (data) => {
      const res = await getChangeConfigByEnv(data);
      // console.log(res);
      return res;
    };

    // 获取可用镜像
    const getMirrorListInfo = async (id) => {
      const res: any = await getMirrorList(id);
      mirrorList.value = res.data;
      // console.log(res);
      return res;
    };

    // 修改服务器镜像
    const getChangeMirrorInfo = async (data) => {
      const res = await getChangeMirror(data);
      // console.log(res);
      return res;
    };

    /**
     * @description 优惠广场
     */

    // 获取可用优惠券
    const getCouponListInfo = async () => {
      const res: any = await getCouponList();
      couponList.value = res.data;
      // console.log(res);
      return res;
    };

    // 领取优惠券
    const getReceiveCouponInfo = async (id) => {
      const res = await getReceiveCoupon(id);
      // console.log(res);
      return res;
    };

    // 获取用户优惠券
    const getUserCouponInfo = async () => {
      const res: any = await getUserCoupon();
      userCouponList.value = res.data;
      // console.log(res);
      return res;
    };

    /**
     * @description 交易订单
     */

    // 获取用户订单
    const getOrderListInfo = async (data) => {
      const res: any = await getOrderList(data);
      orderList.value = res.data;
      // console.log(res);
      return res;
    };

    // 余额充值
    const getRechargeInfo = async (data) => {
      const res = await getRecharge(data);
      // console.log(res);
      return res;
    };

    // cdk 兑换
    const getCDKChargeInfo = async (cdk) => {
      const res = await getCDKCharge(cdk);
      return res;
    };

    /**
     * @description 续费
     */
    const getRenewInfo = async (id) => {
      const res = await getRenew(id);
      // console.log(res);
      return res;
    };

    /**
     * @description 升降配
     */

    // 获取升降配
    const getUpgradeInfo = async (id) => {
      const res: any = await getUpgrade(id);
      upgradeInfo.value = res.data;
      // console.log(res);
      return res;
    };

    // 价格计算
    const getPriceCalcInfo = async (data) => {
      const res = await getPriceCalc(data);
      // console.log(res);
      return res;
    };

    // 提交升降配
    const getSubmitUpgradeInfo = async (data) => {
      const res = await getSubmitUpgrade(data);
      // console.log(res);
      return res;
    };

    /**
     * @description 退款
     */
    const getRefundInfo = async (id) => {
      const res = await getRefund(id);
      // console.log(res);
      return res;
    };

    /**
     * @description 网络管理
     */
    const getNetworkInfo = async (id) => {
      const res = await getNetwork(id);
      networkInfo.value = res;
      // console.log(res);
      return res;
    };

    return {
      userInfo,
      serverList,
      socketsInfo,
      fileList,
      backupList,
      gameConfig,
      mirrorList,
      serverStatus,
      couponList,
      userCouponList,
      orderList,
      upgradeInfo,
      networkInfo,
      getUserMessageInfo,
      getChangePasswordInfo,
      getChangeUserMessageInfo,
      getPhoneCodeInfo,
      getServerListInfo,
      getSocketsAndTokenInfo,
      getFileListInfo,
      getFileContentInfo,
      getDownloadFileInfo,
      getRenameFileInfo,
      getCopyFileInfo,
      getWriteFileInfo,
      getCompressFileInfo,
      getUnCompressFileInfo,
      getDeleteFileInfo,
      getCreateFolderInfo,
      getChangePermissionInfo,
      getUploadLinkInfo,
      getSFTPInfo,
      getServerStatusInfo,
      getBackupListInfo,
      getLockBackupInfo,
      getDeleteBackupInfo,
      getDownloadBackupInfo,
      getStartBackupInfo,
      getRecoverBackupInfo,
      getBuildConfigInfo,
      getChangeConfigByIdInfo,
      getChangeConfigByEnvInfo,
      getMirrorListInfo,
      getChangeMirrorInfo,
      getReinstallInfo,
      getCouponListInfo,
      getReceiveCouponInfo,
      getUserCouponInfo,
      getOrderListInfo,
      getRechargeInfo,
      getRenewInfo,
      getUpgradeInfo,
      getPriceCalcInfo,
      getSubmitUpgradeInfo,
      getRefundInfo,
      getDatabaseStatusInfo,
      getNetworkInfo,
      getCDKChargeInfo,
    };
  },
  {
    // 开启数据缓存
    persist: {
      key: "lizigo_userCenter",
    },
  }
);

export default useUserCenterStore;
