import { fetchData } from "../request";

/**
 * @description 个人设置
 */

// 获取当前登录的用户信息
export function getUserMessage() {
  return fetchData.get({
    url: "client.user.User/getProfile",
  });
}

// 修改用户信息
export function getChangeUserMessage(opts: {}) {
  return fetchData.post({
    url: "client.user.User/editProfile",
    opts,
  });
}

// 修改密码
export function getChangePassword(config: {}) {
  return fetchData.post({
    url: "client.user.User/editPasswordByPhoneCode",
    opts: config,
  });
}

// 手机获取验证码
export function getPhoneCode() {
  return fetchData.get({
    url: "client.user.User/sendPhoneCode",
  });
}

/**
 *
 * @description 我的游戏服-服务器列表
 */

// 获取可用服务器列表
export function getServerList(limit: number, currentPage: number) {
  return fetchData.post({
    url: "client.server.Server/list",
    opts: {
      per_page: limit,
      current_page: currentPage,
    },
  });
}

// 获取服务器状态-守护进程
export function getServerStatus(id: number) {
  return fetchData.get({
    url: `client.server.Server/detail?server_id=${id}`,
  });
}

// 获取服务器状态-数据库中的
export function getDatabaseStatus(id: number) {
  return fetchData.get({
    url: `client.server.Server/status?server_id=${id}`,
  });
}

/**
 * @description 我的游戏服-控制台管理
 */

// 获取sockets地址与token
export function getSocketsAndToken(id) {
  return fetchData.get({
    url: `client.server.Websocket/index?server_id=${id}`,
  });
}

// 重装
export function getReinstall(id: number) {
  return fetchData.post({
    url: `client.server.ServerManage/reinstall`,
    opts: {
      server_id: id,
    },
  });
}

/**
 * @description 我的游戏服-文件管理
 */

// 文件列表
export function getFileList(id: number, path: string) {
  return fetchData.post({
    url: `client.server.FileManage/list`,
    opts: {
      server_id: id,
      path,
    },
  });
}

// 获取文件内容
export function getFileContent(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/content`,
    opts,
  });
}

// 下载文件
export function getDownloadFile(opts) {
  return fetchData.post({
    url: `client.server.FileManage/download`,
    opts,
  });
}

// 重命名文件
// {
//   "server_id": 1,
//   "root": "/",
//   "file": [
//       {
//           "from": "原文件名",
//           "to": "新文件名"
//       }
//   ]
// }
export function getRenameFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/rename`,
    opts,
  });
}

// 复制文件
export function getCopyFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/copy`,
    opts,
  });
}

// 写入文件内容
// {
//   "server_id": 1,
//   "file": "/文件名",
//   "content": "666"
// }
export function getWriteFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/write`,
    opts,
  });
}

// 压缩文件
/* 
{
    "server_id": 1,
    "root": "/",
    "file": [
        "文件夹名",
        "文件名"
    ]

*/
export function getCompressFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/compress`,
    opts,
  });
}

// 解压文件
// {
//   "server_id": 1,
//   "root": "/",
//   "file": "xxx.tar.gz"
// }
export function getDecompressFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/decompress`,
    opts,
    timeout: 15 * 60 * 1000,
  });
}

// 删除文件
// {
//   "server_id": 1,
//   "root": "/",
//   "file": [
//       "xxx.txt"
//   ]
// }
export function getDeleteFile(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/delete`,
    opts,
  });
}

// 新建文件夹
// {
//   "server_id": 1,
//   "name": "lizi",
//   "root": "/"
// }
export function getCreateFolder(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/createFolder`,
    opts,
  });
}

// 修改权限
// {
//   "server_id": 1,
//   "root": "/",
//   "file": [
//       {
//           "file": "a.txt",
//           "mode": "755"
//       }
//   ]
// }
export function getChangePermission(opts: {}) {
  return fetchData.post({
    url: `client.server.FileManage/chmod`,
    opts,
  });
}

// 获取上传链接
export function getUploadLink(id: number) {
  return fetchData.get({
    url: `client.server.FileManage/upload?server_id=${id}`,
  });
}

// 获取sftp连接信息
export function getSFTP(id: number) {
  return fetchData.get({
    url: `client.server.FileManage/sftp?server_id=${id}`,
  });
}

/**
 * @description 优惠广场
 */

// 获取可领取的优惠券列表
export function getCouponList() {
  return fetchData.get({
    url: `client.coupon.Coupon/list`,
  });
}

// 领取优惠券
export function getReceiveCoupon(id: number) {
  return fetchData.get({
    url: `client.coupon.Coupon/pick?coupon_id=${id}`,
  });
}

// 获取用户有的优惠券（包含可用和不可用）
export function getUserCoupon() {
  return fetchData.get({
    url: `client.coupon.Coupon/own`,
  });
}

/**
 * @description 我的游戏服-备份管理
 */

// 服务器备份列表
export function getBackupList(id: number) {
  return fetchData.get({
    url: `client.server.BackupManage/list?server_id=${id}`,
  });
}

// 切换锁定状态
export function getLockBackup(id: number) {
  return fetchData.get({
    url: `client.server.BackupManage/toggleLock?backup_id=${id}`,
  });
}

// 删除备份
export function getDeleteBackup(id: number) {
  return fetchData.get({
    url: `client.server.BackupManage/delete?backup_id=${id}`,
  });
}

// 获取下载链接
export function getDownloadBackup(id: number) {
  return fetchData.get({
    url: `client.server.BackupManage/download?backup_id=${id}`,
  });
}

// 开始备份
export function getStartBackup(opts: {}) {
  return fetchData.post({
    url: `client.server.BackupManage/store`,
    opts,
  });
}

// 备份恢复服务器
export function getRecoverBackup(opts: {}) {
  return fetchData.post({
    url: `client.server.BackupManage/restore`,
    opts,
  });
}

/**
 * @description 我的游戏服-开服配置
 */

// 获取构建配置
export function getBuildConfig(id: number) {
  return fetchData.get({
    url: `client.server.ConfigManage/get?server_id=${id}`,
  });
}

// 修改变量 - 通过id
export function getChangeConfigById(opts: {}) {
  return fetchData.post({
    url: `client.server.ConfigManage/set`,
    opts,
  });
}

// 修改变量 - 通过env
export function getChangeConfigByEnv(opts: {}) {
  return fetchData.post({
    url: `client.server.ConfigManage/setVariableByENV`,
    opts,
  });
}

// 获取可用镜像
export function getMirrorList(id: number) {
  return fetchData.get({
    url: `client.server.ConfigManage/getImage?server_id=${id}`,
  });
}

// 修改服务器镜像
export function getChangeMirror(opts: {}) {
  return fetchData.post({
    url: `client.server.ConfigManage/setImage`,
    opts,
  });
}

/**
 * @description 交易订单
 */

// 用户获取订单
export function getOrderList(opts: {}) {
  return fetchData.post({
    url: `client.invoice.Invoice/list`,
    opts,
  });
}

// 余额充值
export function getRecharge(opts: {}) {
  return fetchData.post({
    url: `client.charge.PayCharge/invoice`,
    opts,
  });
}

// cdk 兑换
export function getCDKCharge(cdk: string) {
  return fetchData.post({
    url: `client.charge.CdkCharge/charge`,
    opts: {
      cdk,
    },
  });
}

/**
 * @description 续费
 */

// 续费
export function getRenew(id: number) {
  return fetchData.post({
    url: `client.product.Product/renew`,
    opts: {
      server_id: id,
    },
  });
}

/**
 * @description 升降配
 */

// 升降配
export function getUpgrade(id: number) {
  return fetchData.post({
    url: `client.product.Product/upgradeOptions`,
    opts: {
      server_id: id,
    },
  });
}

// 价格计算
export function getPriceCalc(opts: {}) {
  return fetchData.post({
    url: `client.product.ServerPrice/upgradeComputer`,
    opts,
  });
}

// 提交升降配信息
export function getSubmitUpgrade(opts: {}) {
  return fetchData.post({
    url: `client.product.Product/upgrade`,
    opts,
  });
}

/**
 * @description 退款
 */

// 退款
export function getRefund(id: number) {
  return fetchData.get({
    url: `client.server.Refund/refund?server_id=${id}`,
  });
}

/**
 * @description 网络管理
 */

// 获取服务器网络信息
export function getNetwork(id: number) {
  return fetchData.get({
    url: `client.server.Network/list?server_id=${id}`,
  });
}
